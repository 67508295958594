<template>
  <div>
    <div class="flex-def flex-cCenter y-box">
      <div class="flex-def flex-zTopBottom flex-cCenter">
        <div style="font-size: 1.4rem">{{$store.state.user.info.agent.balance}}</div>
        <div class="flex-def flex-cCenter">
          <el-button type="text" size="small" style="color: #888888">账户余额</el-button>
          <el-button @click="wantCash" size="small" type="text" style="color: #3cc51f">申请提现</el-button>
        </div>
      </div>
      <div class="flex-def flex-zTopBottom flex-cCenter">
        <div style="font-size: 1.4rem">{{$store.state.user.info.agent.award_rate}}%</div>
        <div class="y-desc">订单佣金比例</div>
      </div>
      <div class="flex-def flex-cCenter flex-zTopBottom">
        <div>{{$store.state.user.info.agent.title}}</div>
        <div class="y-desc">账户名称</div>
      </div>
    </div>
    <div style="padding: 1rem 0 .5rem;color: #888888;font-size: .9rem">账户余额变动明细</div>
    <el-table v-loading="loading" :data="list" border style="width: 100%;">
      <el-table-column prop="id" label="编号"></el-table-column>
      <el-table-column prop="type" :formatter="(item)=>item.type === 1 ? '增加' : '减少'" label="变动类型"></el-table-column>
      <el-table-column prop="fee" label="变动金额"></el-table-column>
      <el-table-column prop="tips" label="变动记录文本" show-overflow-tooltip></el-table-column>
      <el-table-column prop="created_at" label="变动时间" show-overflow-tooltip>
        <template #default="s">
          <div>{{s.row.created_at | date}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="rate" :formatter="(item)=>item.type === 2 ? '':item.rate + '%' " label="佣金比例快照"></el-table-column>
      <el-table-column prop="active_id" :formatter="(item)=>item.type === 2 ? '':item.active_id " label="活动编号"></el-table-column>
      <el-table-column prop="active.title" label="活动名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="order.order_no" label="订单号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="order.member.avatar" label="订单用户头像">
        <template #default="s">
          <div v-if="s.row.order && s.row.order.member">
            <img :src="s.row.order.member.avatar | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem" alt="">
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="order.member.nickname" label="昵称" show-overflow-tooltip></el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
import {mapActions} from "vuex"
export default {
  name: "info",
  data(){
    return{
      loading:false,
      page:1,
      total:0,
      size:0,
      list:[]
    }
  },
  mounted() {
    this.loadList();
  },
  methods:{
    ...mapActions("user",["reloadInfo"]),
    wantCash(){
      this.$api.plugin.agent.UWantCash().then(()=>{
        this.page = 1;
        this.reloadInfo();
        this.loadList();
      })
    },
    pageChange(e) {
      this.page = e;
      this.loadList();
    },
    loadList(){
      this.loading = true;
      this.$api.plugin.agent.UBalanceChange({
        page:this.page
      }).then(res=>{
        this.loading =false;
        this.list = res.list;
        this.total = res.total;
        this.size = res.size;
      })
    },
  }
}
</script>

<style scoped>
  .y-box{
    width: 100%;
    margin: 1rem 0;
  }
  .y-box>div{
    width: 33%;
    border-left: 1px solid #ededed;
  }
  .y-box>div:first-child{
    border-left: unset;
  }
</style>